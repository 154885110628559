.buyButton {
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 1rem;
  color: yellow;
  /* background-color: turquoise; */
  background-color: yellow;
  color: black;
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 3;
  cursor: url('../assets/magicwand.cur'), auto; /* Use a custom cursor */
  box-shadow: 0 0 3rem 0.7rem #ffcc00;
  animation: glowingbig 1.4s infinite alternate;
}

/* .buyButton:hover {
  border: black 0.2rem solid;
} */

@keyframes glowingbig {
  0% {
    box-shadow: 0 0 3rem 0.7rem #ffcc00;
  }
  100% {
    box-shadow: 0 0 3rem 3rem #ffcc00;
  }
}

.glitter-star-buy {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-image: url('../assets/star.png'); /* Replace 'stars.png' with your image */
  background-size: contain;
  animation: stardisappear 2.5s linear forwards; /* Adjust the animation duration here */
  z-index: 2;
}

@keyframes stardisappear {
  0% {
    opacity: 0; /* Start with full opacity */
  }
  30% {
    opacity: 1; /* Gradually fade in to full opacity */
  }
  100% {
    opacity: 0; /* Gradually fade out to zero opacity */
  }
}
