.art-gallery {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-container {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom of the container */
  height: auto;
}

.gallery-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
