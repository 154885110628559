.image-wrapper {
  position: absolute;
  cursor: grab;
  z-index: 2;
  touch-action: none; /* Disable touch scrolling for this element */
  user-select: none; /* Prevent text selection during dragging */
}

.resizable-image {
  width: 100%;
  height: 100%;
}

.delete-icon {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.5);
  color: white;
  font-size: 1.3rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.rotation-handle {
  position: absolute;
  bottom: -2rem;
  left: calc(50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(2, 111, 253, 1);
  cursor: move;
  z-index: 3; /* Ensure it's above the image for interaction */
}
