.image-editor-browse-button {
  border: 0.15rem dashed #ccc;
  padding: 0.7rem;
  text-align: center;
  cursor: pointer;
}


.image-editor-browse-button p {
  font-size: 1rem;
  color: #666;
  width: 27.3rem;
}

.image-editor-browse-button:hover {
  background-color: #f0f0f0;
}

.image-editor-browse-button.active {
  border-color: #007bff;
}

.image-preview {
  margin-top: 2rem;
  border: 01rem solid #ccc;
  padding: 1rem;
  display: inline-block;
}

.image-preview img {
  max-width: 100%;
  max-height: 28rem;
  display: block;
  margin: 0 auto;
}
