.Home {
  height: 100vh;
  cursor: url('../assets/magicwand.cur'), auto; /* Use a custom cursor */
  position: relative;
}

.HomeMain {
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bipoImg {
  position: absolute;
  width: 60rem;
  z-index: 1;
  bottom: 0;
}

.externalLinkIcon {
  width: 1.5rem;
}

.BottomContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.glitter-star {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  /* width: 1rem;
  height: 1rem; */
  background-image: url('../assets/star.png'); /* Replace 'stars.png' with your image */
  background-size: contain;
  /* animation: fall 3s linear forwards; */
  animation: disappear 0.6s linear forwards; /* Adjust the animation duration here */
  cursor: url('../assets/magicwand.cur'), auto; /* Use a custom cursor */
  z-index: 2;
}

@keyframes disappear {
  0% {
    opacity: 1; /* Start with full opacity */
  }
  100% {
    opacity: 0.2; /* Gradually fade out to zero opacity */
  }
}

@media (max-width: 800px) {
  .bipoImg {
    width: 48rem;
  }

  .BottomContainer {
    bottom: 8rem;
  }
}

@media (max-width: 470px) {
  .bipoImg {
    width: 44rem;
  }
}

@media (max-width: 250px) {
  .bipoImg {
    width: 40rem;
  }
}