/* ImageEditor.css */

.image-editor-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100vh; */
  margin-top: 6rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 5rem;
}

.image-editor-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  color: transparent;
}


.controls {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 2rem; */
}

.layer-height, .layer-color {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layer-height-input {
  outline: none;
  width: 4rem;
}

.layer-color-input {
  width: 3rem;
  height: 3rem;
  padding: 0.2rem;
}

.save-image-button, .layer-height-input, .controls {
  font-size: 1.2rem;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls label {
  margin-right: 2rem;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.image-container {
  position: relative;
  display: inline-block;
  margin-top: 0.5rem;
}

/* .image-container-wrapper {
  border: 1px solid red;
} */

.placeholder {
  padding: 0.7rem;
  width: 27.3rem;
  height: 27.3rem;
  border: 0.15rem solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 1.5rem;
}
.image-to-paste {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

.hat-to-paste , .hat-to-paste-selected {
  max-height: 10rem;
  max-width: 10rem;
  -webkit-user-drag: none;  /* For Safari and Chrome */
  -khtml-user-drag: none;   /* For Konqueror browser */
  -moz-user-drag: none;     /* For Firefox */
  -o-user-drag: none;       /* For Opera */
  user-drag: none;          /* Standard property */
}

.image-loaded {
  -webkit-user-drag: none;  /* For Safari and Chrome */
  -khtml-user-drag: none;   /* For Konqueror browser */
  -moz-user-drag: none;     /* For Firefox */
  -o-user-drag: none;       /* For Opera */
  user-drag: none;          /* Standard property */
}

.image-wrappers {
  position: absolute;
}

.image-editor-usage-note {
  font-size: 0.9rem;
  color: #777575;
  margin-top: 1rem;
  text-align: center;
}