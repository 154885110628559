.Links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
}

.LinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: turquoise;
  padding: 0.8rem;
  width: 4rem;
  height: 4rem;
  border-radius: 0.7rem;
  z-index: 3;
  cursor: url('../assets/magicwand.cur'), auto;
  background-color: rgb(255, 123, 0);
}

.LinkContainer:hover {
  background-color: yellow;
  box-shadow: 0 0 1.3rem 0.6rem #ffcc00;
  animation: glowing 1.2s infinite alternate;
}

@keyframes glowing {
0% {
  box-shadow: 0 0 1.3rem 0.6rem #ffcc00;
}
100% {
  box-shadow: 0 0 1.3rem 1.3rem #ffcc00;
}
}

.LinkImage {
  width: 4rem;
}

/* @media (max-width: 800px) {
  .LinkContainer {
    background-color: rgb(255, 123, 0);
  }
} */